import React from 'react';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'How to use StockMatcher',
    path: '/how-to-use-stockmatcher',
    
  },
  {
    title: 'Case studies',
    path: '/case-studies',
   
  },
  {
    title: 'Terms of use',
    path: 'https://drive.google.com/file/d/1S0eb6IsGAYViPHu0gxjakCQnOsTcEomq/view?usp=sharing',
   
  },
  {
    title: 'Release notes',
    path: 'https://drive.google.com/file/d/1OTmALRDg7xKItOEE0m6-evjA6THthnl5/view?usp=sharing',
   
  },
  // {
  //   title: ' Case studies',
    
    

  //   iconClosed: <RiIcons.RiArrowDownSFill color='black'/>,
  //   iconOpened: <RiIcons.RiArrowUpSFill color='black'/>,

  //   subNav: [
  //     {
  //       title: 'Holbein Gardens',
  //       path: '/case-studies/holbein-gardens',
       
  //     },
  //     {
  //       title: '76 Southbank',
  //       path: '/case-studies/southbank',
      
  //     },
  //     {
  //       title: '6-10 St Andrew Street',
  //       path: '/case-studies/andrew-street',
      
  //     },
  //     {
  //       title: 'Timber Square',
  //       path: '/case-studies/timber-square',
      
  //     },
  //     {
  //       title: '1 Exchange Square',
  //       path: '/case-studies/exchange-square',
      
  //     },
  //     {
  //       title: 'Holborn Viaduct',
  //       path: '/case-studies/holborn-viaduct',
      
  //     }
  //   ]
  // },
  {
    title: 'HTS+',
    path: 'https://hts.uk.com/practice/hts-plus/t',
    
  },
  {
    title: 'Contact Us',
 
    // icon: <IoIcons.IoMdHelpCircle />
    path:"",
    iconClosed: <RiIcons.RiArrowDownSFill color='black'/>,
    iconOpened: <RiIcons.RiArrowUpSFill color='black'/>,

    subNav: [
        {
          title: 'info@stockmatcher.co.uk',
          path: 'mailto:info@stockmatcher.co.uk',
        },
      ]
  }
];
