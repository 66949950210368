// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensures that the html and body elements take up the full viewport height */
  font-family: Arial, sans-serif; /* Sets a default font for all text */
  background-color: #e9ecef; /* A light gray background color */
}
body {
  display: flex; /* Set the display to flex */
  flex-direction: column; /* Stack children vertically */
  min-height: 100vh; /* Use at least 100% of the viewport height */
}

.App-layout {
  /* text-align: center; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,YAAY,EAAE,6EAA6E;EAC3F,8BAA8B,EAAE,qCAAqC;EACrE,yBAAyB,EAAE,kCAAkC;AAC/D;AACA;EACE,aAAa,EAAE,4BAA4B;EAC3C,sBAAsB,EAAE,8BAA8B;EACtD,iBAAiB,EAAE,6CAA6C;AAClE;;AAEA;EACE,wBAAwB;EACxB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["html, body {\r\n  margin: 0;\r\n  padding: 0;\r\n  height: 100%; /* Ensures that the html and body elements take up the full viewport height */\r\n  font-family: Arial, sans-serif; /* Sets a default font for all text */\r\n  background-color: #e9ecef; /* A light gray background color */\r\n}\r\nbody {\r\n  display: flex; /* Set the display to flex */\r\n  flex-direction: column; /* Stack children vertically */\r\n  min-height: 100vh; /* Use at least 100% of the viewport height */\r\n}\r\n\r\n.App-layout {\r\n  /* text-align: center; */\r\n  min-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
