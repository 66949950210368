import FirstImage from "../../assets/caseStudies1/JPEG/holbein2.jpg";
import SecondImage from "../../assets/caseStudies1/JPEG/holbein1.jpg";
import ThirdImage from "../../assets/caseStudies1/JPEG/holbein3.jpg";

import SouthBank1 from "../../assets/caseStudies1/JPEG/southbank1.jpg";
import SouthBank2 from "../../assets/caseStudies1/JPEG/southbank2.jpg";
import SouthBank3 from "../../assets/caseStudies1/JPEG/southbank3.jpg";

import Andrew1 from "../../assets/caseStudies1/JPEG/Andrew1.jpg";
import Andrew2 from "../../assets/caseStudies1/JPEG/Andrew2.jpg";

import Timber1 from "../../assets/caseStudies1/JPEG/timber1.jpg";
import Timber2 from "../../assets/caseStudies1/JPEG/timber2.jpg";

import ExchangeSquare1 from "../../assets/caseStudies1/JPEG/exchangeS1.jpg";
import ExchangeSquare2 from "../../assets/caseStudies1/JPEG/exchangeS2.jpg";
import ExchangeSquare3 from "../../assets/caseStudies1/JPEG/exchangeS3.jpg";

import Holborn1 from "../../assets/caseStudies1/JPEG/holborn1.jpg";
import Holborn2 from "../../assets/caseStudies1/JPEG/holborn2.jpg";
import Holborn3 from "../../assets/caseStudies1/JPEG/holborn3.jpg";

export const CaseStudiesdata = [
  {
    title: "Holbein Gardens",
    stage: "Complete",
    Client: "Grosvenor",
    MainContractor: "Blenheim House Construction",
    SteelFabricator: "Cleveland Steel and Tubes",
    Reclaimed: "Cleveland Steel and  Tubes",
    Description: `Holbein Gardens, a refurbished 1980s commercial
        building with a two-storey extension in Belgravia,
        London, is the first HTS project with reused steel in the
        permanent structure. At concept stage in 2019, reuse
        of steel was not being widely discussed in the industry.
        Initially, it was hoped that steel from the demolished
        mansard roof could be used in the new frame; however,
        the time required for dismantling, transport, cleaning,
        testing, and refabrication would not have fit into the
        overall construction programme. Therefore, steel from
        other nearby Grosvenor projects was sourced and
        supplemented by stock from Cleveland Steel. Valuable
        lessons were learned in the process. The frame was
        completed in 2022, including 25 tonnes of reclaimed
        steel, just over a third of the total steel tonnage.`,
    Url: "https://hts.uk.com/projects/holbein-gardens/",
    images: [FirstImage, SecondImage, ThirdImage],
  },
  {
    title: "76 Southbank",
    stage: " Construction",
    Client: "Stanhope",
    MainContractor: "Multiplex",
    SteelFabricator: "Keltbray",
    Reclaimed: "EMR",
    Description: `The Grade II Listed former IBM building at 76 
        Upper Ground in Southwark, London is undergoing 
        redevelopment, modernisation, and extension. In the 
        concept design stages HTS used the Stockmatcher 
        to identify opportunities for reusing steel. Once 
        appointed as the main contractor, Multiplex took on the 
        challenge and have been instrumental in progressing 
        the case for reuse by getting all parties together to 
        work through all the considerations, particularly around 
        fabrication tolerances of reclaimed steel. EMR as the 
        reclaimed steel specialist have taken over the matching 
        process and are using product passports for lifetime 
        material tracking. The reused steel will be exposed 
        and expressed in the final structure. Of 230 tonnes of 
        new steel overall, it is expected that 35 will be from 
        reclaimed stock. `,
    Url: "https://hts.uk.com/projects/76-southbank/",
    images: [SouthBank1, SouthBank2, SouthBank3],
  },
  {
    title: "6-10 St Andrew Street",
    stage: "Construction",
    Client: "GPE",
    MainContractor: "Faithdean",
    SteelFabricator: "Cleveland Steel and Tubes",
    Reclaimed: "Cleveland Steel and Tubes",
    Description: `6-10 St Andrew Street, a refurbishment and single storey extension in the City of London, 
        was being used for trials during the final development 
        of the HTS Stockmatcher. The HTS team showed the speculative
        match results (based on stock available at the time) to the
        client team during a meeting, displaying a high match 
        percentage and corresponding large reduction in embodied carbon.
        The client was enthusiastic and requested reused steel to be
        incorporated in the final design. As the project was near
        the procurement stage, the process was quickly started,
        and steel elements were reserved in the stockyard with
        minimal delay. The project is currently on site with 
        final fabrication drawings due in November. Out of the 
        34 tonnes of steel used, an estimated 25 tonnes are 
        anticipated to be from reused elements. `,
    Url: "https://hts.uk.com/projects/6-10-st-andrew-street/",
    images: [Andrew1, Andrew2],
  },
  {
    title: "Timber Square",
    stage: "Construction",
    Client: "Landsec",
    MainContractor: "Mace",
    SteelFabricator: "William Hare Group",
    Reclaimed: "Cleveland Steel and Tubes",
    Description: `Timber Square is the redevelopment of a large-scale site in Southwark, London, with two distinct 
        buildings: the Print Building (refurbishment and 
        extension) and the Ink Building (new build). The 
        potential for reusing steel was identified at the end 
        of the design stage with the HTS Stockmatcher. 
        Since then, 115 tonnes of reclaimed steel has been 
        secured by the steel fabricator, who reported back 
        that they had only been able to match such a high 
        number of elements due to the Stockmatcher. 
        Reclaimed steel will be used in both buildings as 
        part of the primary steel frame. The procurement 
        process has been straightforward, and the 
        fabricators have received and started installing this steel with the frame due to compete in the next few months. `,
    Url: "https://hts.uk.com/projects/timber-square/",
    images: [Timber1, Timber2],
  },
  {
    title: "1 Exchange Square",
    stage: "Construction",
    Client: "LaSalle and PMB",
    MainContractor: "Multiplex",
    SteelFabricator: "Bourne Steel",
    Reclaimed: "EMR",
    Description: `1 Exchange Square, a new development in the City 
        of London, was one of the projects used for testing 
        during final development of the HTS Stockmatcher. 
        The scheme involves a vertical and horizontal 
        extension to an existing 1990s steel framed building 
        with a large exoskeleton feature structure suitable 
        for the incorporation of reused steel. Multiplex and 
        EMR have taken on the challenge we expect about 
        50 tonnes of reused steel to be implemented. `,
    Url: "https://hts.uk.com/projects/one-exchange-square/",
    images: [ExchangeSquare1, ExchangeSquare2, ExchangeSquare3],
  },
  {
    title: "Holborn Viaduct",
    stage: "Construction",
    Client: "Royal London Asset Management",
    MainContractor: "Multiplex",
    SteelFabricator: "BHC",
    Reclaimed: "EMR",
    Description: `Holborn Viaduct is a new 16-floor office building in 
        the centre of London. The HTS team did detailed 
        studies to find the optimum balance between 
        reclaimed steel and new grade S460 for the 
        columns, as well as considering reused sections for 
        beams across all floors. 100 tonnes of reclaimed 
        steel is currently reserved in EMR’s stockyard, 
        awaiting use on site, and represents 4% of the 
        overall steel tonnage on the project. `,
    Url: "https://hts.uk.com/projects/holborn-viaduct/",
    images: [Holborn1, Holborn2, Holborn3],
  },
];
