import React from "react";
import { NavigationBar } from "./NavigationBar";
import "../styles/pageLayout.css";
import HtsFooterLogo from "../assets/htsFooterLogo.png";

export const PageLayout = (props) => {
  let currentURL = window.location.href;
  return (<>
  <div style={{marginBottom:"100px"}}>
       <NavigationBar />
  </div>    
      <div className="page_body">{props.children}</div>
  
      <footer className="Footer">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "20px",
            backgroundColor:
              currentURL === "https://stockmatcher.co.uk/" ||
              currentURL === "http://localhost:3000/"
                ? "#FFFFFF"
                : "#A6DFD8",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "left",
              gap: "70px",
            }}
          >
            <a
              href="https://drive.google.com/file/d/1KT4eXSxFrcondSCbBgRx7BxGDSfR1uB8/view?usp=sharing"
              className="footer-links"
              target="_blank"
              rel="noopener noreferrer"
            >
              User guide
            </a>
            <a
              href="https://drive.google.com/file/d/1S0eb6IsGAYViPHu0gxjakCQnOsTcEomq/view?usp=sharing"
              className="footer-links"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of use
            </a>
            <a
              href="https://drive.google.com/file/d/1OTmALRDg7xKItOEE0m6-evjA6THthnl5/view?usp=sharing"
              className="footer-links"
              target="_blank"
              rel="noopener noreferrer"
            >
              Release{" "}
              {currentURL === "http://localhost:3000/" ? "notes" : "note"}
            </a>
          </div>

          <div style={{ marginRight: "20px" }}>
            <a href="https://hts.uk.com/">
              <img
                style={{ width: "90px", height: "57.6px" }}
                src={HtsFooterLogo}
                alt="HtsFooterLogo"
              />
            </a>
          </div>
        </div>
      </footer>
  </>
 
  );
};
