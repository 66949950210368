import React, { useState } from "react";
import {
  Box,
  Container,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import { StatusIndicator } from "@cloudscape-design/components";

import Carousel from "react-bootstrap/Carousel";
import "./CaseStudy.css";
const CaseStudy = ({ caseStudyData }) => {
  return (
    <article className="call-to-action">
      <Container>
        <div className="headerStyle">{caseStudyData.title}</div>

        <div style={{ marginTop: "2rem" }}>
          <div>
            <div className="labelStyle">Stage</div>
            <div style={{ fontFamily: "Apercu Light", marginBottom: "1rem" }}>
              {caseStudyData.stage}
            </div>
          </div>

          <div>
            <div className="labelStyle">Client</div>
            <div style={{ fontFamily: "Apercu Light", marginBottom: "1rem" }}>
              {caseStudyData.Client}
            </div>
          </div>

          <div>
            <div className="labelStyle">Main Contractor</div>
            <div
              style={{
                fontFamily: "Apercu Light",
                marginBottom: "1rem",
                color: "black",
              }}
            >
              {caseStudyData.MainContractor}
            </div>
          </div>

          <div>
            <div style={{ fontFamily: "Apercu Light", marginBottom: "1rem" }}>
              <div className="labelStyle">Steel Fabricator</div>
              {caseStudyData.SteelFabricator}
            </div>
          </div>

          <div>
            <div className="labelStyle">Reclaimed Steel Supplier</div>
            <div style={{ fontFamily: "Apercu Light", marginBottom: "1rem" }}>
              {caseStudyData.Reclaimed}
            </div>
          </div>

          <div style={{ fontFamily: "Apercu Light", marginBottom: "1rem" }}>
            {caseStudyData.Description}
          </div>

          <div>
            <div className="labelStyle">Read more about this project</div>
            <div style={{ fontFamily: "Apercu Light", marginBottom: "1rem" }}>
              <a
                href={caseStudyData.Url}
                style={{ color: "#AE2132", fontFamily: "FS Sinclair Medium" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                click here to read
              </a>
            </div>
          </div>
        </div>
      </Container>

      <section className="carousel-section" style={{ marginLeft: "2%", borderRadius: "10px" }}>
        <Carousel
          indicators={false}
          data-bs-theme="dark"
          style={{ maxWidth: "100%", overflow: "hidden" }}
        >
          {caseStudyData.images.map((image, index) => (
            <Carousel.Item
              key={index}
              style={{ maxWidth: "100%", overflow: "hidden" }}
            >
              {/* <div className="image-container">
          <div className={`skeleton ${imageLoaded ? 'hide' : ''}`}></div>
          <img
            className={`d-block w-100 caseStudyImg ${imageLoaded ? 'loaded' : 'loading'}`}
            src={image}
            alt={`Slide ${index + 1}`}
            loading="lazy"
            onLoad={handleImageLoad}
            style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain", margin: "0 auto" }}
          />
        </div> */}

              <div className="image-box">
                <div
                  className={"images"}
                  style={{
                    backgroundImage: `url(${image})`,
                    height: "50em",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {!image && <div className="skeleton"></div>}
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </section>
    </article>
  );
};

export default CaseStudy;
