import React from "react";
import { useState, useEffect } from "react";
import "../styles/homePage.css";
import mainPageGif from "../assets/homePageSketch.gif";

const HTSStockMatcherHomePage = () => {
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  useEffect(() => {
    document.title = "Stockmatcher | Homepage";
    window.addEventListener("resize", detectSize);
  }, []);

  function detectSize() {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  }

  let gifImageTopMargin = "120px";
  let gifWidth = windowDimenion.winWidth - windowDimenion.winWidth * 0.158 * 2;
  let gifheight = gifWidth * 0.71;

  if (windowDimenion.winWidth < 1200) {
    gifImageTopMargin = "70px";
  }

  //Set the Return here
  return (
    <>
      {/* {console.log(windowDimenion.winWidth)} */}

      <div style={{display:"flex" , justifyContent:"center"}}>
        <img
          style={{
            width: gifWidth,
            height: gifheight,
          }}
          src={mainPageGif}
          alt="mainPageGif"
        />
      </div>

      <div style={{ backgroundColor: "#A6DFD8" }}>
        <div
          style={{
            marginLeft: windowDimenion.winWidth * 0.158,
            marginRight: windowDimenion.winWidth * 0.158,
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontFamily: "Apercu",
              fontSize: "25px",
              textAlign: "left",
              color: "#000000",
            }}
          >
            The HTS Stockmatcher is a selection tool built to assist procurement
            of reclaimed steel for use in new construction projects. It is free
            to use and requires no licenced software.
          </p>

          <h4
            style={{
              fontFamily: "FS Sinclair Bold",
              fontSize: "22px",
              textAlign: "left",
              color: "#AC263B",
            }}
          >
            What it does:
          </h4>

          <p
            style={{
              fontFamily: "Apercu Light",
              fontSize: "20px",
              textAlign: "left",
              color: "#000000",
            }}
          >
            The system compares two lists of information: a list of reclaimed
            steel, and a list of design steel. It finds where design elements
            may be substituted with reclaimed elements. The matching process
            considers geometric and simple structural properties, along with the
            waste produced through offcuts and over-specification. The
            Stockmatcher reviews all suitable substitutions and returns the most
            efficient matches.
          </p>

          <br />

          {/* <h4
            style={{
              fontFamily: "FS Sinclair Bold",
              fontSize: "22px",
              textAlign: "left",
              color: "#AC263B",
            }}
          >
            How to use HTS Stockmatcher:
          </h4> */}

          <div
            style={{
              width:
                (windowDimenion.winWidth -
                  2 * (windowDimenion.winWidth * 0.158)) /
                2,
              height:
                ((windowDimenion.winWidth -
                  2 * (windowDimenion.winWidth * 0.158)) /
                  2) *
                0.5625,
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/917974423?h=7bffb24edc&loop=1"
              width="100%"
              height="100%"
              frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              title="HTS Stockmatcher Walkthrough"
              allowfullscreen
            ></iframe>
          </div>

          <br />
          <h4
            style={{
              fontFamily: "FS Sinclair Bold",
              fontSize: "22px",
              textAlign: "left",
              color: "#AC263B",
            }}
          >
            Key features:
          </h4>

          <p
            style={{
              fontFamily: "Apercu Light",
              fontSize: "20px",
              textAlign: "left",
              color: "#000000",
            }}
          >
            + Simple spreadsheet input and output. <br />
            + Assignment of offcuts produced.
            <br />
            + Reporting of unused offcut sections.
            <br />
            + Optimisation to maximise efficiency.
            <br />
          </p>

          <br />
{/* 
          <h4
            style={{
              fontFamily: "FS Sinclair Bold",
              fontSize: "22px",
              textAlign: "left",
              color: "#AC263B",
            }}
          >
            Please note:
          </h4>

          <p
            style={{
              fontFamily: "Apercu Light",
              fontSize: "20px",
              textAlign: "left",
              color: "#000000",
            }}
          >
            The Stockmatcher is not a structural design package. The structural
            check is limited to a comparison of second moment of area. This can
            identify appropriate matches for most cases, where deflections
            govern the design. However, it is the specifier’s responsibility to
            check that matches achieve any other controlling design criteria or
            project specific requirements.
          </p> */}

          <br />

          <h4
            style={{
              fontFamily: "FS Sinclair Bold",
              fontSize: "22px",
              textAlign: "left",
              color: "#AC263B",
            }}
          >
            Our motivations
          </h4>

<p
  style={{
    fontFamily: "Apercu Light",
    fontSize: "20px",
    textAlign: "left",
    color: "#000000",
  }}
>
  The Stockmatcher was developed by structural engineers and researchers at
  Heyne Tillett Steel and made available to the wider industry free of charge.
  Our hope is that it will facilitate the wider adoption of reused steel,
  accelerating practical application of circular economy principles and help to
  reduce our industry’s significant carbon footprint. As founding signatories
  of Structural & Civil Engineers Declare, we understand that knowledge
  sharing and collaboration is critical to help us develop and adopt new
  innovative solutions to the climate crisis. Feedback on the tool is welcomed
  – contact us at{" "}
  <a href="mailto:info@stockmatcher.co.uk">info@stockmatcher.co.uk</a>
</p>

          <br />

          <h4
            style={{
              fontFamily: "FS Sinclair Bold",
              fontSize: "22px",
              textAlign: "left",
              color: "#AC263B",
            }}
          >
            Acknowledgements:
          </h4>

          <p
            style={{
              fontFamily: "Apercu Light",
              fontSize: "20px",
              textAlign: "left",
              color: "#000000",
            }}
          >
            This ongoing project has not been possible without the help of our
            industry peers. We thank those below for their help so far: <br />
            ▪ Cleveland Steel and Tubes Ltd <br />▪ Eberhard Unternehmungen
          </p>

          <br />

          <h4
            style={{
              fontFamily: "FS Sinclair Bold",
              fontSize: "22px",
              textAlign: "left",
              color: "#AC263B",
            }}
          >
            About HTS+
          </h4>

          <p
            style={{
              fontFamily: "Apercu Light",
              fontSize: "20px",
              textAlign: "left",
              color: "#000000",
              paddingBottom: "50px",
            }}
          >
            HTS+ is the self-funded research initiative of Heyne Tillett Steel,
            a structural and civil engineering practice with a reputation for
            delivering intelligent low carbon solutions on complex, urban sites.
            Working closely with industry partners and universities, HTS+
            develops and tests new techniques and technologies, generating
            solutions for complex or unsolved structural and civil engineering
            challenges.
          </p>
        </div>
      </div>
    </>
  );
};

export const StockMatcherHP = () => {
  return <HTSStockMatcherHomePage />;
};
