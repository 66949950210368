import React from "react";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import htsLogo from "../assets/stockMatcherLogo.png";
import menuLogo from "../assets/menuLogo.png";
import rightArrow from "../assets/rightArrow.png";
import { useState, useEffect } from "react";
import "../styles/navigation.css";
import {FaBars, FaTimes} from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from "./SideBarData";
import SubMenu from "./SubMenu";
import { Link } from "react-router-dom";

export const NavigationBar = () => {
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    // document.title = 'Heyne Tillett Steel - HTS';
    window.addEventListener("resize", detectSize);
  }, []);

  function detectSize() {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  }

  let htsStockMatcherImageWidth = 320;
  let htsStockMatcherImageHeight = 73.5;
  let windowsWidth = windowDimenion.winWidth * 0.03;
  let mainContainerHeight = 65;
  let shadowContainer = "10px";
  let navPadding = "17px";
  let menuDrawerHeight = "55px";
  let menuDrawerWidth = "55px";
  let menuDrawerMarginLeft = "30px";
  let arrowComponentSize = "22px";

  if (windowDimenion.winWidth < 1200) {
    htsStockMatcherImageWidth = 180;
    htsStockMatcherImageHeight = 41.1;
    mainContainerHeight = 42;
    shadowContainer = "5px";
    menuDrawerHeight = "40px";
    menuDrawerWidth = "40px";
    menuDrawerMarginLeft = "15px";
    arrowComponentSize = "14px";
  }

  return (
    <>
      {/* {console.log(windowDimenion.winWidth)} */}

      <Navbar
        bg="#A6DFD8"
        variant="light"
        fixed="top"
        style={{
          borderBottom: "2px solid #A6DFD8",
          backgroundColor: "#A6DFD8",
          paddingTop: navPadding,
          zIndex: 10,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        className="navBar1"
      >
        <Link to="/">
          <img
            style={{
              width: htsStockMatcherImageWidth,
              height: htsStockMatcherImageHeight,
              marginLeft: windowsWidth * 0.5,
            }}
            src={htsLogo}
            alt="htsLogo"
          />
        </Link>
        <div className="rightSection">
          <div className="ms-auto">
            <div
              style={{
                position: "relative",
                width: htsStockMatcherImageWidth,
                height: mainContainerHeight,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: shadowContainer,
                  left: shadowContainer,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  opacity: 0.8,
                  zIndex: 1,
                }}
              ></div>
              <Link to="/stock-matcher">
                <div
                  style={{
                    padding: "0 10px 0 10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    zIndex: 2,
                  }}
                >
                  <div
                    style={{
                      color: "#000000",
                      fontFamily: "FS Sinclair Bold",
                      fontSize: arrowComponentSize,
                      marginRight: "20px",
                    }}
                  >
                    Go to Stockmatcher
                  </div>
                  <img
                    style={{ width: "35px", height: "35px" }}
                    src={rightArrow}
                    alt="rightArrow"
                  />
                </div>
              </Link>
            </div>
          </div>
        
      {!isDrawerOpen ? (
        <FaBars
          onClick={() => toggleDrawer()} 
          style={{
            marginLeft: menuDrawerMarginLeft,
            height: menuDrawerHeight,
            width: menuDrawerWidth,
          }}
  
        />
      ) : (
        <FaTimes
          onClick={() => toggleDrawer()} 
          style={{
            marginLeft: menuDrawerMarginLeft,
            height: menuDrawerHeight,
            width: menuDrawerWidth,
          }}
    
        />
      )}
 
        </div>
      </Navbar>

      <div
        className={`drawer ${isDrawerOpen ? "open" : ""}`}
        
      >
        <div className="drawer-content">
          {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} toggleDrawer={toggleDrawer}/>;
            })}

        </div>
      </div>
    </>
  );
};
