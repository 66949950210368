// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../htsFonts/FSSinclair-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../htsFonts/apercu_medium_rs.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../htsFonts/apercu_light_rs.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "FS Sinclair Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

@font-face {
  font-family: "Apercu";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}

@font-face {
  font-family: "Apercu Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
}

.para-light {
  /* font-family: "apercu_lightregular"; */
  font-size: 15px;
  color: #464646;
  text-align: left;
}

.para-light.bold {
  font-weight: bold;
}

.headers {
  /* font-family: "apercu_lightregular"; */
  font-size: 20px;
  color: #464646;
  text-align: left;
  font-weight: bolder;
}

iframe {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}`, "",{"version":3,"sources":["webpack://./src/styles/homePage.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,+DAA8D;AAChE;;AAEA;EACE,qBAAqB;EACrB,+DAA+D;AACjE;;AAEA;EACE,2BAA2B;EAC3B,+DAA8D;AAChE;;AAEA;EACE,wCAAwC;EACxC,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,sEAAsE;AACxE","sourcesContent":["@font-face {\r\n  font-family: \"FS Sinclair Bold\";\r\n  src: url(\"../htsFonts/FSSinclair-Bold.otf\") format(\"opentype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Apercu\";\r\n  src: url(\"../htsFonts/apercu_medium_rs.otf\") format(\"opentype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Apercu Light\";\r\n  src: url(\"../htsFonts/apercu_light_rs.otf\") format(\"opentype\");\r\n}\r\n\r\n.para-light {\r\n  /* font-family: \"apercu_lightregular\"; */\r\n  font-size: 15px;\r\n  color: #464646;\r\n  text-align: left;\r\n}\r\n\r\n.para-light.bold {\r\n  font-weight: bold;\r\n}\r\n\r\n.headers {\r\n  /* font-family: \"apercu_lightregular\"; */\r\n  font-size: 20px;\r\n  color: #464646;\r\n  text-align: left;\r\n  font-weight: bolder;\r\n}\r\n\r\niframe {\r\n  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
