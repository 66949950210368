import React from 'react'
import HeroSection from './HeroSection'
import MainContent from './MainContent'
const HowToUseSm = () => {
  return (
    <div >
    <HeroSection title="How to use the HTS Stockmatcher" subtitle="All you need to know to get started and efficiently use the HTS Stockmatcher." />
    <MainContent />
    </div>
  )
}

export default HowToUseSm
