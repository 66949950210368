import React from 'react';
import { FaDownload } from 'react-icons/fa';

import './MainContent.css';
import { Link } from 'react-router-dom';

const MainContent1 = () => {
  return (
    <div>

<div id="about" style={{padding:"70px" ,backgroundColor:"whitesmoke"}}>
  <div className="container">
    <div className="row">
    
      <div className="col-12 col-md-6">
        <div className="about-text">
          <h2 style={{ color: "#ac263b" , fontFamily:"FS Sinclair Bold"}}>Quick Start Video Tutorial</h2>
          <p style={{fontFamily:"apercu light", fontWeight:"bold"}}>Discover how to use the HTS Stockmatcher with our comprehensive video guide. Learn about the key steps, how to input data and how run the matching process.</p>
          {/* <h3>Why Choose Us?</h3> */}
          <div className="list-style row">
            <div className="col-lg-6 col-sm-6 col-12">
              {/* <ul>
                {{ props.data ? props.data.Why.map((d, i) => (
                  <li key={`${d}-${i}`}>{d}</li>
                )) : 'loading' }}
              </ul> */}
            </div>
            <div className="col-lg-6 col-sm-6 col-12">
              {/* <ul>
                {{ props.data ? props.data.Why2.map((d, i) => (
                  <li key={`${d}-${i}`}> {d}</li>
                )) : 'loading' }}
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
      <iframe className='video-tutorial'
          src="https://player.vimeo.com/video/917974423?h=7bffb24edc&loop=1"
          width="90%"
          height="100%"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 

          allowFullScreen
          title="HTS Stockmatcher Tutorial"
        ></iframe>
      </div>
    </div>
  </div>
</div>


<div id="about" style={{padding:"70px" , backgroundColor:"whitesmoke" ,display:"flex" , flexDirection:"column", alignItems:"center" }} >
 
    
    {/* <div className="col-12 col-md-6">
        <div className="about-text">
          <h2 style={{ color: "#ac263b" , fontFamily:"FS Sinclair Bold"}}>Quick Guide to Getting Started</h2>
          <p>Discover how to use the HTS Stockmatcher with our comprehensive video guide. Learn about the key steps, how to input data and how run the matching process.</p>
         
        </div>
      </div> */}
      <div className="cardBox" style={{ width:"70%" }}>
      <h3 className='text-center py-3=5' style={{fontFamily:"FS Sinclair Bold" ,  color: "#ac263b"}}>User Guide</h3>
      <div  style={{marginTop:"10px" ,  display:"flex" , justifyContent:"space-around"}}>
            <div>
            <ul className="custom-list" style={{ fontFamily:"apercu light",fontWeight:"bold"}}>
            <li >Overview of the HTS Stockmatcher interface.</li>
            <li >How to create and manage your projects.</li>
        </ul>
            </div>
            <div >
              <ul className="custom-list" style={{ fontFamily:"apercu light", fontWeight:"bold"}}>
              
          <li>Understanding the matching process and interpreting results.</li>
                  <li>Troubleshooting</li>
               
              </ul>
            </div>
          </div>
       <Link to="https://drive.google.com/file/d/1KT4eXSxFrcondSCbBgRx7BxGDSfR1uB8/view?usp=sharing" style={{textDecoration:"none" , display:"flex" , alignItems:"center"}}>
        <button className='hero-button1' style={{ margin:"0 auto" , marginTop:"30px"}}><FaDownload /><span style={{marginLeft:"10px"}}>Download full user guide</span></button>
        </Link>
      </div>
      
 
 
</div>

    <div className="main-content " style={{paddingLeft:"12%" , paddingRight:"12%"}}>
<div className="custom-gray-divider mb-3 " style={{position:"relative" , top:"-63px"}}></div>
     
      <section className="case-studies" style={{textAlign:"center" }}>
        <h2  style={{marginBottom:"15px" , color: "#ac263b" , fontFamily:"FS Sinclair Bold"}}>See HTS Stockmatcher in Action</h2>
        <p  style={{fontFamily:"Apercu Light" , fontWeight:"bold"}}>Explore case studies where the HTS Stockmatcher was used to match reclaimed steel for use in construction projects</p>
        <Link to="/case-studies"  style={{textDecoration:"none"}}>

        <button className='hero-button1' style={{ margin:"0 auto" }}>Explore</button>
</Link>
      </section>

      <section className="please-note" style={{textAlign:"center" , marginTop:"7%"}}>
        <h2  style={{marginBottom:"15px" , color: "#ac263b" , fontFamily:"FS Sinclair Bold"}}>Important Information</h2>
        <p  style={{fontFamily:"Apercu Light" , fontWeight:"bold"}}>The Stockmatcher is not a structural design package. The structural check is limited to a comparison of second moment of area.<br></br> This can identify appropriate matches for most cases, where deflections govern the design.<br></br> However, it is the specifier’s responsibility to check that matches achieve any other controlling design criteria or project specific requirements.</p>
      </section>
    </div>

 

    </div>

  );
};

export default MainContent1;
