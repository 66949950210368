// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../htsFonts/FSSinclair-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../htsFonts/apercu_medium_rs.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../htsFonts/apercu_light_rs.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.wrapper_body {
  box-sizing: border-box;
  min-height: calc(100vh);
  /* min-width: 100vw; */
  display: flex;
  flex-direction: column;
  margin: 0;
  background: linear-gradient(rgba(176, 222, 230, 0.281), rgba(32, 170, 55, 0)),
}
.page_footer {
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 10;
}

.page_body {
  background-color: #fff; /* White background for the content area */
  border-radius: 8px; /* Rounded corners for the content box */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  flex-grow: 1;
}

@font-face {
  font-family: "FS Sinclair Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

@font-face {
  font-family: "Apercu";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}

@font-face {
  font-family: "Apercu Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
}

.footer-links {
  color: #000000;
  text-decoration: none;
  font-family: "Apercu";
  font-size: 17px;
}

.footer-links:hover {
  color: #ac263b;
}
`, "",{"version":3,"sources":["webpack://./src/styles/pageLayout.css"],"names":[],"mappings":";AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT;AACF;AACA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;AACb;;AAEA;EACE,sBAAsB,EAAE,0CAA0C;EAClE,kBAAkB,EAAE,wCAAwC;EAC5D,uCAAuC,EAAE,4BAA4B;EACrE,YAAY;AACd;;AAEA;EACE,+BAA+B;EAC/B,+DAA8D;AAChE;;AAEA;EACE,qBAAqB;EACrB,+DAA+D;AACjE;;AAEA;EACE,2BAA2B;EAC3B,+DAA8D;AAChE;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["\r\n.wrapper_body {\r\n  box-sizing: border-box;\r\n  min-height: calc(100vh);\r\n  /* min-width: 100vw; */\r\n  display: flex;\r\n  flex-direction: column;\r\n  margin: 0;\r\n  background: linear-gradient(rgba(176, 222, 230, 0.281), rgba(32, 170, 55, 0)),\r\n}\r\n.page_footer {\r\n  flex-grow: 0;\r\n  flex-shrink: 0;\r\n  z-index: 10;\r\n}\r\n\r\n.page_body {\r\n  background-color: #fff; /* White background for the content area */\r\n  border-radius: 8px; /* Rounded corners for the content box */\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */\r\n  flex-grow: 1;\r\n}\r\n\r\n@font-face {\r\n  font-family: \"FS Sinclair Bold\";\r\n  src: url(\"../htsFonts/FSSinclair-Bold.otf\") format(\"opentype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Apercu\";\r\n  src: url(\"../htsFonts/apercu_medium_rs.otf\") format(\"opentype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Apercu Light\";\r\n  src: url(\"../htsFonts/apercu_light_rs.otf\") format(\"opentype\");\r\n}\r\n\r\n.footer-links {\r\n  color: #000000;\r\n  text-decoration: none;\r\n  font-family: \"Apercu\";\r\n  font-size: 17px;\r\n}\r\n\r\n.footer-links:hover {\r\n  color: #ac263b;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
