import React from "react";
import { useState, useEffect } from "react";
import "../styles/stockMatcher.css";
import Button from "react-bootstrap/Button";
import rightArrow from "../assets/rightArrow.png";
import { Modal } from "react-bootstrap";
import { createData } from "../fetch";

const HTSStockMatcher = () => {
  const [generatedGoogleSheetUrl, setGeneratedGoogleSheetUrl] = useState("");
  const [runInputSheetUrl, setRunInputSheetUrl] = useState("");
  const [openInputSheetUrl, setOpenInputSheetUrl] = useState("");
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [responseMessage, setResponseMessage] = useState(" ");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [statusResult, setStatusResult] = useState(["No data"]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openNewTab = (url) => (url !== "" ? window.open(url, "_blank") : null);

  useEffect(() => {
    document.title = "Stockmatcher | Run";
    window.addEventListener("resize", detectSize);
  }, []);

  function detectSize() {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  }

  const LoadingScreen = () => {
    // console.log("loadingText", loadingMessage)

    return (
      <div className="loading-screen">
        <div className="loading-overlay"></div>
        <div className="loading-content">
          <div className="spinner"></div>
          <div className="loading-text">{loadingMessage}</div>
        </div>
      </div>
    );
  };

  const handleGenerateNewInputSheet = async () => {
    window.gtag("event", "New sheet", {
      event_category: "Button",
      event_label: "New sheet",
    });
    setLoadingMessage("Generating New Sheet");
    setLoading(true);
    try {
      const url =
        "https://htsstockmatcherbackendfunctions.azurewebsites.net/api/createNewStockmatchSheet";
      const data = { name: "Stockmatcher Sheet" };

      const sheetUrl = await createData(url, data);
      setGeneratedGoogleSheetUrl(sheetUrl);
      // console.log('Created data:', generatedGoogleSheetUrl);
      setResponseMessage(
        "Success! Stockmatcher sheet generated. \n Sheet URL: " +
          sheetUrl.toString() +
          "\n If a new window hasn't opened, click here"
      );
      handleShow();
      openNewTab(sheetUrl);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      setResponseMessage(
        "Problem in generating a new sheet, please try again in a few minutes. If the issue persists please contact us."
      );
      handleShow();
    } finally {
      setLoading(false);
    }
  };

  const handleCalculateInputSheet = async () => {
    window.gtag("event", "Run calculations", {
      event_category: "Button",
      event_label: "Run calculations",
    });
    if (runInputSheetUrl !== "") {
      try {
        setStatusResult(["No data"]);
        setLoadingMessage("Sheet Calculations");
        setLoading(true);
        // console.log(runInputSheetUrl.split("/"))
        let inputSheetId = " ";
        runInputSheetUrl.split("/").length === 6
          ? (inputSheetId = runInputSheetUrl.split("/").reverse()[0])
          : (inputSheetId = runInputSheetUrl.split("/").reverse()[1]);

        const url =
          "https://htsstockmatcherbackendfunctions.azurewebsites.net/api/runStockmatch";

        const data = { spreadsheet_id: inputSheetId };
        const response = await createData(url, data);
        // console.log(JSON.parse(response.slice(9).toString().replace(/'/g, '"')))
        setStatusResult(
          JSON.parse(response.slice(9).toString().replace(/'/g, '"'))
        );
        setResponseMessage(
          "Stockmatcher calculations complete! Refer to sheet for detailed results. "
        );
        handleShow();
      } catch (error) {
        const response = error;
        // console.log("Ultimate Error", response["response"]["data"])
        setLoading(false);

        response["response"]["data"].includes("Quota exceeded for quota metric")
          ? setResponseMessage(
              "Sorry, the Stockmatcher is busy! \n All resources are in use. Please try again after 1 minute."
            )
          : response["response"]["data"].includes(
              'Missing required parameter "spreadsheetId"'
            ) ||
            response["response"]["data"].includes('returned "Not Found"') ||
            response["response"]["data"].includes(
              "Requested entity was not found"
            )
          ? setResponseMessage(
              "There's an issue with this Stockmatcher sheet URL. Please check and try again."
            )
          : setResponseMessage(
              "There's an issue with some inputs on the Stockmatcher sheet. Please check and try again. \n Refer to the user guide for further assistance. "
            );
        handleShow();
      } finally {
        setLoading(false);
      }
    }
  };

  let mainLeftMargin = windowDimenion.winWidth * 0.158;
  let mainRightMargin = windowDimenion.winWidth * 0.158;
  let displayFlex = "flex";
  let divWidth = "50%";
  let divTopMargin = "120px";
  let containerWidth = "350px";
  let containerHeight = "120px";
  let shadowContainer = "10px";
  let rightDivPadding =
    (
      (windowDimenion.winWidth - 2 * (windowDimenion.winWidth * 0.158)) / 2 -
      360
    ).toString() + "px";
  let allDivFlex = "initial";
  let allDivflexDirection = "row";
  let allDivJustifiedContent = "center";
  let resultSize = "15px";

  if (windowDimenion.winWidth < 1200) {
    mainLeftMargin = "0px";
    mainRightMargin = "0px";
    displayFlex = "initial";
    divWidth = "100%";
    divTopMargin = "90px";
    rightDivPadding = "0px";
    allDivFlex = "flex";
    allDivflexDirection = "column";
    allDivJustifiedContent = "flex-end";
    resultSize = "15px";
  }

  //Set the Return here
  return (
    <>
      {/* {console.log("url", runInputSheetUrl)} */}
      {loading && (
        <div>
          <LoadingScreen />
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseMessage.split("\n").map((line, index) => (
            <div key={index}>
              {line.includes("click here") ? (
                <>
                  <i>If a new window hasn't opened, </i>
                  <a
                    style={{ color: "#000000", textDecoration: "none" }}
                    href={generatedGoogleSheetUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>
                      <b>click here</b>
                    </i>
                  </a>
                </>
              ) : (
                line
              )}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        style={{
          marginLeft: mainLeftMargin,
          marginRight: mainRightMargin,
          marginTop: "30px",
          display: displayFlex,
        }}
      >
        <div
          style={{
            width: divWidth,
            display: allDivFlex,
            paddingBottom: "50px",
            alignItems: "center",
            justifyContent: allDivJustifiedContent,
            flexDirection: allDivflexDirection,
          }}
        >
          <div
            style={{
              fontFamily: "FS Sinclair Bold",
              textAlign: "left",
              color: "#000000",
              fontSize: "25px",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            Step 1
          </div>

          <div
            style={{
              fontFamily: "FS Sinclair Bold",
              textAlign: "left",
              color: "#AC263B",
              fontSize: "25px",
            }}
          >
            New project:
          </div>

          <div
            style={{
              position: "relative",
              width: containerWidth,
              height: containerHeight,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: shadowContainer,
                left: shadowContainer,
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                opacity: 0.8,
                zIndex: 1,
              }}
            ></div>
            <div
              onClick={handleGenerateNewInputSheet}
              style={{
                cursor: "pointer",
                border: "4px solid #A6DFD8",
                padding: "0 10px 0 10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                backgroundColor: "#FFFFFF",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  color: "#000000",
                  fontFamily: "FS Sinclair Bold",
                  fontSize: "22px",
                  marginRight: "20px",
                }}
              >
                Create new input sheet
              </div>
              <img
                style={{ width: "40px", height: "40px", cursor: "pointer" }}
                src={rightArrow}
                alt="rightArrow"
              />
            </div>
          </div>
        </div>

        <div
          style={{
            width: divWidth,
            display: allDivFlex,
            paddingLeft: rightDivPadding,
            alignItems: "center",
            justifyContent: allDivJustifiedContent,
            flexDirection: allDivflexDirection,
          }}
        >
          <div
            style={{
              fontFamily: "FS Sinclair Bold",
              textAlign: "left",
              color: "#000000",
              fontSize: "25px",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            Step 2
          </div>

          <div
            style={{
              fontFamily: "FS Sinclair Bold",
              textAlign: "left",
              color: "#AC263B",
              fontSize: "25px",
            }}
          >
            Run Stockmatcher:
          </div>
          <div
            style={{
              position: "relative",
              width: containerWidth,
              height: containerHeight,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: shadowContainer,
                left: shadowContainer,
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                opacity: 0.8,
                zIndex: 1,
              }}
            ></div>
            <div
              style={{
                padding: "10px 10px 0 10px",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                backgroundColor: "#A6DFD8",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  color: "#000000",
                  fontFamily: "FS Sinclair Bold",
                  fontSize: "22px",
                  marginRight: "20px",
                }}
              >
                Sheet URL:
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  marginTop: "20px",
                }}
              >
                <input
                  type="text"
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    border: "none",
                    width: "80%",
                    marginRight: "20px",
                    fontFamily: "Apercu",
                  }}
                  onChange={(e) => setRunInputSheetUrl(e.target.value)}
                />
                <img
                  style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  src={rightArrow}
                  alt="rightArrow"
                  onClick={handleCalculateInputSheet}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginLeft: mainLeftMargin,
          marginRight: mainRightMargin,
          marginTop: divTopMargin,
          display: displayFlex,
        }}
      >
        <div
          style={{
            width: divWidth,
            display: allDivFlex,
            paddingBottom: "50px",
            alignItems: "center",
            justifyContent: allDivJustifiedContent,
            flexDirection: allDivflexDirection,
          }}
        >
          <div
            style={{
              fontFamily: "FS Sinclair Bold",
              textAlign: "left",
              color: "#AC263B",
              fontSize: "25px",
            }}
          >
            Load previous:
          </div>
          <div
            style={{
              position: "relative",
              width: containerWidth,
              height: containerHeight,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: shadowContainer,
                left: shadowContainer,
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                opacity: 0.8,
                zIndex: 1,
              }}
            ></div>
            <div
              style={{
                border: "4px solid #A6DFD8",
                padding: "10px 10px 0 10px",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                backgroundColor: "#FFFFFF",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  color: "#000000",
                  fontFamily: "FS Sinclair Bold",
                  fontSize: "22px",
                  marginRight: "20px",
                }}
              >
                Sheet URL:
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "left",
                  justifyContent: "left",
                  marginTop: "20px",
                }}
              >
                <input
                  type="text"
                  style={{
                    backgroundColor: "#A6DFD8",
                    color: "#000000",
                    border: "none",
                    width: "80%",
                    marginRight: "20px",
                    fontFamily: "Apercu",
                  }}
                  onChange={(e) => setOpenInputSheetUrl(e.target.value)}
                />
                <img
                  style={{ width: "40px", height: "40px", cursor: "pointer" }}
                  src={rightArrow}
                  alt="rightArrow"
                  onClick={() => openNewTab(openInputSheetUrl)}
                />
              </div>
            </div>
          </div>
        </div>
        {statusResult[0] === "No data" ? null : (
          <div
            style={{
              width: divWidth,
              paddingLeft: rightDivPadding,
              display: allDivFlex,
              paddingBottom: "50px",
              alignItems: "center",
              justifyContent: allDivJustifiedContent,
              flexDirection: allDivflexDirection,
            }}
          >
            <div
              style={{
                fontFamily: "FS Sinclair Bold",
                textAlign: "left",
                color: "#AC263B",
                fontSize: "25px",
              }}
            >
              Input Summary
            </div>
            <div
              style={{
                border: "4px solid #A6DFD8",
                padding: "10px 10px 0 10px",
                width: "350px",
              }}
            >
              {statusResult[0] === "No data"
                ? null
                : statusResult.slice(0, 7).map((data, index) =>
                    data[1] === "bold" ? (
                      <p
                        key={index}
                        style={{
                          fontFamily: "FS Sinclair Bold",
                          fontSize: resultSize,
                          textAlign: "left",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        {data[0]}
                      </p>
                    ) : data[2] === "space" ? (
                      <p
                        key={index}
                        style={{
                          fontFamily: "Apercu Light",
                          fontSize: resultSize,
                          textAlign: "left",
                          color: "#000000",
                          marginBottom: "10px",
                        }}
                      >
                        {data[0]}
                      </p>
                    ) : (
                      <p
                        key={index}
                        style={{
                          fontFamily: "Apercu Light",
                          fontSize: resultSize,
                          textAlign: "left",
                          color: "#000000",
                          marginBottom: "0px",
                        }}
                      >
                        {data[0]}
                      </p>
                    )
                  )}
            </div>

            <div
              style={{
                fontFamily: "FS Sinclair Bold",
                textAlign: "left",
                color: "#AC263B",
                fontSize: "25px",
                marginTop: "30px",
              }}
            >
              Match Results
            </div>

            <div
              style={{
                border: "4px solid #A6DFD8",
                padding: "10px 10px 0 10px",
                width: "350px",
              }}
            >
              {statusResult[0] === "No data"
                ? null
                : statusResult
                    .slice(7, statusResult.length)
                    .map((data, index) =>
                      data[1] === "bold" ? (
                        <p
                          key={index}
                          style={{
                            fontFamily: "FS Sinclair Bold",
                            fontSize: resultSize,
                            textAlign: "left",
                            color: "#000000",
                            marginBottom: "0px",
                          }}
                        >
                          {data[0]}
                        </p>
                      ) : data[2] === "space" ? (
                        <p
                          key={index}
                          style={{
                            fontFamily: "Apercu Light",
                            fontSize: resultSize,
                            textAlign: "left",
                            color: "#000000",
                            marginBottom: "10px",
                          }}
                        >
                          {data[0]}
                        </p>
                      ) : (
                        <p
                          key={index}
                          style={{
                            fontFamily: "Apercu Light",
                            fontSize: resultSize,
                            textAlign: "left",
                            color: "#000000",
                            marginBottom: "0px",
                          }}
                        >
                          {data[0]}
                        </p>
                      )
                    )}
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          marginLeft: mainLeftMargin,
          marginRight: mainRightMargin,
          marginTop: divTopMargin,
        }}
      >
        <p
          style={{
            fontFamily: "Apercu Light",
            fontSize: "15px",
            textAlign: "center",
            color: "#000000",
            wordWrap: "break-word",
          }}
        >
          ▪ Each Stockmatcher project sheet has its own unique URL, found in the
          browser search bar.
          <br />
          ▪ This is used as a reference for running the Stockmatcher and
          retrieving the project at a later date. Please make a note of it.
          <br />▪ We do not store your personal data; however, by using this
          tool you accept that information stored within the stockmatcher
          project is publicly accessible via the sheet URL.
        </p>
      </div>
    </>
  );
};

export const StockMatcherPage = () => {
  return <HTSStockMatcher />;
};
