import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.css";

import { PageLayout } from "../src/components/PageLayout";
import { StockMatcherPage } from "./pages/stockMatcher";
import { StockMatcherHP } from "./pages/homePage";
import CaseStudy from "./pages/caseStudies/CaseStudy";
import { CaseStudiesdata } from "./pages/caseStudies/CaseStudiesData";
import HowToUseSm from "./pages/howToUseSM/HowToUseSm";
import CaseStudyMain from "./pages/caseStudies/CaseStudyMain";

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<StockMatcherHP />} />
      <Route path="/stock-matcher" element={<StockMatcherPage />} />
      <Route path="/how-to-use-stockmatcher" element={<HowToUseSm />} />
      <Route path="/case-studies" element={<CaseStudyMain />} />
      <Route path="/case-studies/holbein-gardens" element={<CaseStudy caseStudyData={CaseStudiesdata[0]} />} />
      <Route path="/case-studies/southbank" element={<CaseStudy caseStudyData={CaseStudiesdata[1]} />} /> 
      <Route path="/case-studies/andrew-street" element={<CaseStudy caseStudyData={CaseStudiesdata[2]} />} />
      <Route path="/case-studies/timber-square" element={<CaseStudy caseStudyData={CaseStudiesdata[3]} />} />
      <Route path="/case-studies/exchange-square" element={<CaseStudy caseStudyData={CaseStudiesdata[4]} />} />
      <Route path="/case-studies/holborn-viaduct" element={<CaseStudy caseStudyData={CaseStudiesdata[5]} />} />
    </Routes>
  );
};

const App = ({ instance }) => {
  return (
    <div className="App-layout">
      <Router>
        <PageLayout>
          <Pages />
        </PageLayout>
      </Router>
    </div>
  );
};

export default App;
