// src/Card.js
import React from 'react';
import { Link , useNavigate } from 'react-router-dom';

const Card = ({ theme, imgSrc, title, date, description , link}) => {
const navigate = useNavigate();
const handleNavigate = () => {
  navigate(link); // Use navigate with the link prop
};

  return (
    <>
    <article className={`postcard ${theme} basix`}  onClick={handleNavigate}>
      <a className="postcard__img_link" href="#">
        <img className="postcard__img" src={imgSrc} alt="Image Title" />
      </a>
      <div className="postcard__text" style={{backgroundColor:"white"}}>
        <h1 className={`postcard__title`}><a href="#">{title}</a></h1>
        <div className="postcard__subtitle small">
          <time dateTime="2020-05-25 12:00:00">
            <i className="fas fa-calendar-alt mr-2" style={{color:"black"}}>Client - </i>{date}
          </time>
        </div>
        <div className="postcard__bar"></div>
        <div className="postcard__preview-txt">{description}</div>
        {/* <ul className="postcard__tagbox"> */}
          {/* <li className="tag__item"><i className="fas fa-tag mr-2"></i>{stage}</li> */}
          {/* <li className={`tag__item play`}> */}
            
          {/* </li> */}
        {/* </ul> */}
      </div>
      <a href="/case-studies/holbein-gardens" style={{ color: "white" }}>
  {/* <i className="fas fa-play mr-2" style={{ fontFamily: "FS Sinclair Medium" }}></i>Link to Page */}
</a>

    </article>
            {/* <Link to={link} onClick={clickeddd}><i className="fas fa-play mr-2" style={{fontFamily:"FS Sinclair Medium" , color:"black"}}></i>Link to Pageeeeee</Link> */}
    </>
  );
};

export default Card;
