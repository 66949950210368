import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa";
import styled from 'styled-components';

// Define your styled components here
const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 16px;
  
  &:hover {
    background: White;
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  background: #a9dcd8;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 15px;

  &:hover {
    background: white;
    border-left: 4px solid #632ce4;
    cursor: pointer;
  }
`;

// Your SubMenu component starts here
const SubMenu = ({ item, toggleDrawer }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSubnav(!subnav);
  };

  const handleLinkClick = (event) => {
    event.stopPropagation();
    if(!item.subNav) {
      toggleDrawer();
    }
  };

  return (
    <>
      <SidebarLink to={item.path} onClick={handleLinkClick} >
        <div onClick={item.subNav && showSubnav}>
          <label className='link'>{item.title}</label>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav && item.subNav.map((item, index) => (
        <DropdownLink to={item.path} key={index} >
          <FaChevronRight color='black'/>
          <label className='link submenu'>{item.title}</label>
        </DropdownLink>
      ))}
    </>
  );
};

export default SubMenu;
