import styled from 'styled-components';
import heroImage from '../../assets/hero1.jpg'
import './HeroSection.css';
import first from '../../assets/first.png';
import second from '../../assets/second.png';
import third from '../../assets/third.png';

export default function HeroSection({ title, subtitle }) {
    return (
      <div className='hero-body'>
      {/* <div className="background"></div>*/}
        <div className="background-texture"></div>
        <header className="header">
          <div className="left-content">
            {/* <p className="stay-home">Stay Home!!</p> */}
            <h1 className='hero-h1' style={{color:"black"}}>{title}</h1>
            <p className="para" style={{color:"black"}}>
              {subtitle}
            </p>
            {/* <button className='hero-button'>Get Started</button> */}
          </div>
       
          {/* Add your SVG here */}
        </header>
      </div>
    );
  }