import React from "react";
import SecondImage from "../../assets/caseStudies1/JPEG/holbein1.jpg";
import HolbeinImage from "../../assets/hol2.jpg";
import SouthBank1 from "../../assets/caseStudies1/JPEG/southbank1.jpg";
import Andrew1 from "../../assets/caseStudies1/JPEG/Andrew1.jpg";
import Timber1 from "../../assets/caseStudies1/JPEG/timber1.jpg";
import ExchangeSquare1 from "../../assets/caseStudies1/JPEG/exchangeS1.jpg";
import Holborn1 from "../../assets/caseStudies1/JPEG/holborn1.jpg";

import Card from "../../components/Card";
import "./CaseStudyMain.css";

const CaseStudyMain = () => {
  const cards = [
    {
      theme: "dark blue",
      imgSrc: ExchangeSquare1,
      title: "1 Exchange Square",
      stage: "Construction",
      link: "/case-studies/exchange-square",
      date: "LaSalle and PMB",
      description:
        "1 Exchange Square in London features a large exoskeleton suitable for the incorporation of about 50 tonnes of reused steel.",
    },
    {
      theme: "dark blue",
      imgSrc: Timber1,
      title: "Timber Square",
      stage: "Construction",
      link: "/case-studies/timber-square",
      date: "Landsec",
      description:
        "Timber Square is the redevelopment of a large-scale site in London. Using the HTS Stockmatcher led to 115 tonnes of reclaimed steel has been secured by the steel fabricator for use in the primary frame.",
    },
    {
      theme: "dark blue",
      imgSrc: Holborn1,
      title: "Holborn Viaduct",
      stage: "Construction",
      link: "/case-studies/holborn-viaduct",
      date: "Royal London Asset Management",
      description:
        "Holborn Viaduct in London is a new steel framed office building, which is expected to contain 100 tonnes of reused steel.",
    },
    {
      theme: "dark blue",
      imgSrc: Andrew1,
      title: "6-10 St Andrew Street",
      stage: "Construction",
      link: "/case-studies/andrew-street",
      date: "GPE",
      description:
        "6-10 St Andrew Street in London is a single-storey extension to an existing building, with HTS using the Stockmatcher to identify reuse opportunities. The project is expected to incorporate 25 tonnes of reclaimed steel into the new structure.",
    },
    {
      theme: "dark blue",
      imgSrc: HolbeinImage,
      title: "Holbein Gardens",
      stage: "Complete",
      link: "/case-studies/holbein-gardens",
      date: "Grosvenor",
      description:
        "Holbein Gardens in London, a revamped 1980s building, became HTS's first project to incorporate reused steel in its structure, learning valuable lessons from sourcing steel from other projects and completing the frame in 2022 with 25 tonnes of reclaimed steel.",
    },

    {
      theme: "dark blue",
      imgSrc: SouthBank1,
      title: "76 Southbank",
      stage: " Construction",
      link: "/case-studies/southbank",
      date: "Stanhope",
      description:
        "The former IBM building at 76 Upper Ground, London, is being redeveloped with a focus on sustainability, using Stockmatcher for steel reuse opportunities and employing EMR for material tracking. The project aims to incorporate 35 tonnes of reclaimed steel into the 230 tonnes planned for the new structure.",
    },
  ];
  return (
    <div>
      {/* <h1 className="text-center py-3" >Case Studies</h1> */}
      <section className="light" style={{ backgroundColor: "#dce7e7" }}>
        <h1
          className="text-center"
          style={{
            color: "#ac263b",
            fontFamily: "FS Sinclair Bold",
            paddingTop: "20px",
          }}
        >
          Case Studies
        </h1>
        <p
          className="text-center"
          style={{ color: "black", fontFamily: "Apercu" }}
        >
          Explore real-world applications and success stories of how HTS
          Stockmatcher has transformed project management and decision-making
          processes.
        </p>
        <div className="container py-5">
          {cards.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </div>
      </section>
      {/* Similarly for the light section... */}
    </div>
  );
};

export default CaseStudyMain;
